import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import { Property } from '@models/properties';
import { AccommReservation, MealPlaceDetail, MealPlaceAdditionalMeal } from '@models/reservations';
import './assets/style.scss';

const tableHeader = [
  '部屋番号',
  '利用者名',
  '時間',
  '人数（大/小）',
  '料理名',
  '数量',
  '会場名',
  'テーブル番号',
  '備考',
];

interface ReportTableProps {
  rsvs: AccommReservation[];
  property: Property;
  selectedDate: Date;
}

const ReportTable: React.FC<ReportTableProps> = ({ rsvs, property, selectedDate }) => {
  const [filteredRsv, setFilteredRsv] = useState<AccommReservation[]>([]);

  useEffect(() => {
    const rsvHaveMealPlace: AccommReservation[] = [];

    rsvs.forEach((rsv) => {
      rsv.roomList.forEach((roomList) => {
        if (
          roomList.mealPlace?.breakfast.length ||
          roomList.mealPlace?.lunch.length ||
          roomList.mealPlace?.dinner.length
        )
          rsvHaveMealPlace.push(rsv);
      });
    });

    setFilteredRsv(rsvHaveMealPlace);
  }, [rsvs]);

  const mealTimes = [
    {
      name: '【朝食】',
      type: 'breakfast',
    },
    {
      name: '【昼食】',
      type: 'lunch',
    },
    {
      name: '【夕食】',
      type: 'dinner',
    },
  ];
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <b>配膳表</b>
        <b>{moment(selectedDate).format('YYYY-MM-DD (ddd)')}</b>
      </div>
      {mealTimes.map((mealTime) => {
        return (
          <>
            <div className="mt-3">
              <b>{mealTime.name}</b>
            </div>
            <Table>
              <thead>
                <tr>
                  {tableHeader.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredRsv
                  .map((rsv, rsvIndex) => {
                    return rsv.roomList.map((room, roomIndex) => {
                      const combined: MealPlaceDetail[] = _.concat(
                        mealTime.type === 'breakfast'
                          ? room.mealPlace?.breakfast ?? []
                          : mealTime.type === 'lunch'
                          ? room.mealPlace?.lunch ?? []
                          : room.mealPlace?.dinner ?? []
                      );

                      const _combined = combined.filter(
                        (item) => item.date === moment(selectedDate).format('YYYY-MM-DD')
                      );

                      if (_combined.length === 0) return null;

                      const typeMeal = property.mealPlans.find((x) => x.id === _combined[0].planId)?.type;
                      const additionalMeal: MealPlaceAdditionalMeal[] =
                        typeMeal === 'breakfast'
                          ? _.concat(room.mealPlace?.breakfastAdditional ?? [])
                          : typeMeal === 'lunch'
                          ? _.concat(room.mealPlace?.lunchAdditional ?? [])
                          : _.concat(room.mealPlace?.dinnerAdditional ?? []);
                      const additionalMealFirst = additionalMeal.filter(
                        (item) => item.date === moment(selectedDate).format('YYYY-MM-DD')
                      );
                      const rowSpan = additionalMealFirst.length > 0 ? additionalMealFirst.length + 1 : 1;
                      let _rowSpan = 0;
                      _combined.slice(1).forEach((item) => {
                        const rowSpan2 = additionalMeal.length > 0 ? additionalMeal.length + 1 : 1;
                        _rowSpan = _rowSpan + rowSpan2;
                      });

                      return (
                        <>
                          <tr key={`${rsvIndex}-${roomIndex}-1`}>
                            <td rowSpan={rowSpan + _rowSpan} className="position-relative">
                              <div
                                className={additionalMealFirst.length > 0 || _combined.length > 1 ? 'table-collum' : ''}
                              >
                                {room.roomName}
                              </div>
                            </td>
                            <td rowSpan={rowSpan + _rowSpan} className="position-relative">
                              <div
                                className={additionalMealFirst.length > 0 || _combined.length > 1 ? 'table-collum' : ''}
                              >
                                {room.guestName}
                              </div>
                            </td>
                            <td rowSpan={rowSpan} className="position-relative">
                              <div
                                className={additionalMealFirst.length > 0 && _combined.length > 0 ? 'table-collum' : ''}
                              >
                                {_combined[0].time ? moment(_combined[0].time).format('HH:mm') : ''}
                              </div>
                            </td>
                            <td rowSpan={rowSpan + _rowSpan} className="position-relative">
                              <div
                                className={additionalMealFirst.length > 0 || _combined.length > 1 ? 'table-collum' : ''}
                              >
                                {`${room.paxCount.adult}/${
                                  room.paxCount.child1 +
                                  room.paxCount.child2 +
                                  room.paxCount.child3 +
                                  room.paxCount.child4 +
                                  room.paxCount.child5 +
                                  room.paxCount.child6
                                }`}
                              </div>
                            </td>
                            <td>{property.mealPlans.find((x) => x.id === _combined[0].planId)?.name ?? ''}</td>
                            <td>{_combined[0].guestNum}</td>
                            <td rowSpan={rowSpan} className="position-relative">
                              <div
                                className={additionalMealFirst.length > 0 && _combined.length > 0 ? 'table-collum' : ''}
                              >
                                {property.mealVenues.find((x) => x.id === _combined[0].placeId)?.name ?? ''}
                              </div>
                            </td>
                            <td rowSpan={rowSpan} className="position-relative">
                              <div
                                className={additionalMealFirst.length > 0 && _combined.length > 0 ? 'table-collum' : ''}
                              >
                                {_combined[0].tableNo}
                              </div>
                            </td>
                            <td rowSpan={rowSpan + _rowSpan} className="position-relative">
                              <div
                                className={additionalMealFirst.length > 0 || _combined.length > 1 ? 'table-collum' : ''}
                                style={{ whiteSpace: 'pre-wrap' }}
                              >
                                {room.mealPlace?.mealMemo ?? ''}
                              </div>
                            </td>
                          </tr>
                          {additionalMealFirst.map((item, index) => (
                            <tr key={`${rsvIndex}-${roomIndex}-${index}`}>
                              <td>{property.accommSalesItems.find((x) => x.itemId === item.itemId)?.name ?? ''}</td>
                              <td>{item.itemNum}</td>
                            </tr>
                          ))}

                          {_combined.slice(1).map((mealPlace, mealIndex) => {
                            const typeMeal = property.mealPlans.find((x) => x.id === mealPlace.planId)?.type;
                            const additionalMeal: MealPlaceAdditionalMeal[] =
                              typeMeal === 'breakfast'
                                ? _.concat(room.mealPlace?.breakfastAdditional ?? [])
                                : typeMeal === 'lunch'
                                ? _.concat(room.mealPlace?.lunchAdditional ?? [])
                                : _.concat(room.mealPlace?.dinnerAdditional ?? []);
                            const _additionalMeal = additionalMeal.filter(
                              (item) => item.date === moment(selectedDate).format('YYYY-MM-DD')
                            );
                            const rowSpan2 = _additionalMeal.length > 0 ? _additionalMeal.length + 1 : 1;
                            return (
                              <>
                                <tr key={mealPlace.placeId}>
                                  <td rowSpan={rowSpan2} className="position-relative">
                                    <div className={rowSpan2 > 1 ? 'table-collum' : ''}>
                                      {moment(mealPlace.time).format('HH:mm')}
                                    </div>
                                  </td>
                                  <td>{property.mealPlans.find((x) => x.id === mealPlace.planId)?.name ?? ''}</td>
                                  <td>{mealPlace.guestNum}</td>
                                  <td rowSpan={rowSpan2} className="position-relative">
                                    <div className={rowSpan2 > 1 ? 'table-collum' : ''}>
                                      {property.mealVenues.find((x) => x.id === mealPlace.placeId)?.name ?? ''}
                                    </div>
                                  </td>
                                  <td rowSpan={rowSpan2} className="position-relative">
                                    <div className={rowSpan2 > 1 ? 'table-collum' : ''}>{mealPlace.tableNo}</div>
                                  </td>
                                </tr>

                                {_additionalMeal.map((item, index) => (
                                  <tr key={`${rsvIndex}-${roomIndex}-${index}`}>
                                    <td>
                                      {property.accommSalesItems.find((x) => x.itemId === item.itemId)?.name ?? ''}
                                    </td>
                                    <td>{item.itemNum}</td>
                                  </tr>
                                ))}
                              </>
                            );
                          })}
                        </>
                      );
                    });
                  })
                  .flat()}
              </tbody>
            </Table>
          </>
        );
      })}
    </div>
  );
};

export default ReportTable;

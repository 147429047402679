type APIDefinedErrorDetail = {
  code: string;
  message: string;
};

type APIDefinedError = {
  detail: APIDefinedErrorDetail;
};

type ValidationErrorDetail = {
  loc: string[];
  msg: string;
  type: string;
};

type ValidationError = {
  detail: ValidationErrorDetail;
};

type ClientError = {
  code?: string;
  message: string;
};

type ServerError = APIDefinedError | ValidationError;
type RequestError = ServerError | ClientError;

// Following custom errors are written for Promise.reject(),
// since it is preferred to reject a error class rather than a object.
// see https://eslint.org/docs/rules/prefer-promise-reject-errors

class APIDefinedErrorCls extends Error {
  // define a custom error in typescript
  // see https://stackoverflow.com/questions/41102060
  constructor(detail: APIDefinedErrorDetail) {
    super();
    Object.setPrototypeOf(this, APIDefinedErrorCls.prototype);

    this.name = 'APIDefinedError';
    this.detail = detail;
  }

  detail;
}

class ValidationErrorCls extends Error {
  constructor(detail: ValidationErrorDetail) {
    super();
    Object.setPrototypeOf(this, ValidationErrorCls.prototype);

    this.name = 'ValidationError';
    this.detail = detail;
  }

  detail;
}

class ClientErrorCls extends Error {
  constructor(message: string, code?: string) {
    super(message);
    Object.setPrototypeOf(this, ClientErrorCls.prototype);

    this.name = 'ClientError';
    this.code = code;
  }

  code?: string;
}

export { APIDefinedErrorCls, ClientErrorCls, ValidationErrorCls };
export type { APIDefinedError, ClientError, RequestError, ServerError, ValidationError };

import { AccommRsvPlanActionTypes } from './actions';
import { accommRsvPlanActions } from './constants';

import { AccommPlan } from '@models/properties';

const INIT_STATE = { accommRsvPlan: null };

type AccommRsvPlanState = {
  accommRsvPlan: AccommPlan | null;
};

const AccommRsvPlan = (
  state: AccommRsvPlanState = INIT_STATE,
  action: AccommRsvPlanActionTypes
): AccommRsvPlanState => {
  switch (action.type) {
    case accommRsvPlanActions.SET_RSV_VALUE:
      return {
        ...action.payload,
        accommRsvPlan: { ...action.payload },
      };
    default:
      return { ...state };
  }
};

export { INIT_STATE };
export default AccommRsvPlan;
export type { AccommRsvPlanState };

import { AccommRsvInDayTripActionTypes } from './actions';
import { accommRsvInDayTripActions } from './constants';

import { AccommReservation } from '@models/in_day_trip_reservations';

const INIT_STATE = {
  accommRsvInDayTrip: {
    id: '',
    propId: '',
    guestId: '',
    guestName: '',
    bookingDate: '',
    arrivalDate: '',
    itemRecords: [],
    planRecords: [],
    taxRecords: [],
    depositRecords: [],
    operateHistory: [],
    createTime: '',
    updateTime: '',
  },
};

type AccommRsvInDayTripState = {
  accommRsvInDayTrip: AccommReservation;
};

const AccommRsvInDayTrip = (
  state: AccommRsvInDayTripState = INIT_STATE,
  action: AccommRsvInDayTripActionTypes
): AccommRsvInDayTripState => {
  switch (action.type) {
    case accommRsvInDayTripActions.SET_RSV_VALUE:
      return {
        ...action.payload,
        accommRsvInDayTrip: { ...action.payload },
      };
    default:
      return { ...state };
  }
};

export { INIT_STATE };
export default AccommRsvInDayTrip;
export type { AccommRsvInDayTripState };

import { GuestActions } from './constants';

import { Guest } from '@models/guests';

type GuestTypes = {
  type: string;
  payload: Guest[];
};

export const setGuests = (guests: Guest[]): GuestTypes => ({
  type: GuestActions.SET_VALUE,
  payload: guests,
});

export type { GuestTypes };

import { AxiosResponse } from 'axios';

import { APICore } from './apiCore';

import { Base64Object, CreateObjectResp, DeleteObjectResp } from '@models/objects';

const api = new APICore();

const getImageObject = (objectName: string): Promise<AxiosResponse<Blob>> => {
  return api.getBlob(`/objects/images/${objectName}`);
};

const createImageObject = (data: Base64Object): Promise<AxiosResponse<CreateObjectResp>> => {
  return api.postJSON(`/objects/images`, data);
};

const deleteImageObject = (objectName: string): Promise<AxiosResponse<DeleteObjectResp>> => {
  return api.delete(`/objects/images/${objectName}`);
};

export { getImageObject, createImageObject, deleteImageObject };

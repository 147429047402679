import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { getAllProperty } from '@helpers/api/properties';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { setPropValue } from '@redux/prop/actions';

const LOCAL_PROP_KEY = 'currentProp';

export interface PropSelectorItem {
  label: string;
  value: string;
}

type Selected = PropSelectorItem | null;

interface PropSelectorProps {
  onChange: (value: Selected) => void;
}

const PropSelector: React.FC<PropSelectorProps> = (props) => {
  const dispatch = useAppDispatch();
  const { fromStatePropId } = useAppSelector((state) => ({
    fromStatePropId: state.Prop.value,
  }));

  const [selected, setSelected] = useState<Selected>(null);
  const [options, setOptions] = useState<PropSelectorItem[]>([]);

  useEffect(() => {
    getAllProperty().then((resp) => {
      const newOptions = resp.data.map((prop) => ({ label: prop.name, value: prop.id }));
      setOptions(newOptions);

      const curSelected = getSelectedProp();
      if (curSelected) {
        const matched = newOptions.find((x) => x.value === curSelected);
        if (matched) setSelected(matched);
        if (!fromStatePropId) dispatch(setPropValue(curSelected));
      } else {
        setSelected(newOptions[0]);
        localStorage.setItem(LOCAL_PROP_KEY, newOptions[0].value);
        if (!fromStatePropId) {
          dispatch(setPropValue(newOptions[0].value));
        }
      }
    });
  }, [dispatch, fromStatePropId]);

  const handleChange = (curSelected: Selected) => {
    if (curSelected) {
      localStorage.setItem(LOCAL_PROP_KEY, curSelected.value);
      setSelected(curSelected);
      dispatch(setPropValue(curSelected.value));
    }
    props.onChange(curSelected);
  };

  return (
    <div style={{ minWidth: '180px' }}>
      <Select
        components={{ DropdownIndicator: null, IndicatorSeparator: null }}
        id={'property-selector'}
        isMulti={false}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        onChange={handleChange}
        options={options}
        placeholder={'対象施設を選択'}
        value={selected}
      />
    </div>
  );
};

function getSelectedProp() {
  return localStorage.getItem(LOCAL_PROP_KEY);
}

export { PropSelector, getSelectedProp, LOCAL_PROP_KEY };

import { AccommRsvEditorActionTypes } from './actions';
import { accommRsvEditorActions } from './constants';

import { AccommReservation } from '@models/reservations';

const INIT_STATE = { accommRsv: null };

type AccommRsvEditorState = {
  accommRsv: AccommReservation | null;
};

const AccommRsvEditor = (
  state: AccommRsvEditorState = INIT_STATE,
  action: AccommRsvEditorActionTypes
): AccommRsvEditorState => {
  switch (action.type) {
    case accommRsvEditorActions.SET_RSV_VALUE:
      return {
        ...action.payload,
        accommRsv: { ...action.payload },
      };
    default:
      return { ...state };
  }
};

export default AccommRsvEditor;
export type { AccommRsvEditorState };

import { PropActionTypes } from './actions';
import { PropActions } from './constants';

const INIT_STATE = {};

type PropState = {
  value?: string | null;
};

const Prop = (state: PropState = INIT_STATE, action: PropActionTypes): PropState => {
  switch (action.type) {
    case PropActions.SET_PROP_VALUE:
      return {
        ...state,
        value: action.payload.value,
      };
    default:
      return { ...state };
  }
};

export default Prop;
export type { PropState };

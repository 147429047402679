import { PropertyActions } from './constants';

import { Property } from '@models/properties';

type PropertyTypes = {
  type: string;
  payload: Property;
};

export const setProperty = (property: Property): PropertyTypes => ({
  type: PropertyActions.SET_PROPERTY_VALUE,
  payload: property,
});

export type { PropertyTypes };

import React from 'react';

import Datepicker from './Datepicker';

interface DatepickerRangeProps {
  startDate?: Date;
  endDate?: Date;
  handleStartDateChange?: (date: Date | null) => void;
  handleEndDateChange?: (date: Date | null) => void;
}

const DatepickerRange: React.FC<DatepickerRangeProps> = ({
  startDate = null,
  endDate = null,
  handleStartDateChange = () => false,
  handleEndDateChange = () => false,
}) => {
  return (
    <div className="d-flex align-items-center w-auto">
      <div>
        <Datepicker value={startDate} onChange={handleStartDateChange} hideAddon />
      </div>
      <div className="mx-2">~</div>
      <div>
        <Datepicker value={endDate} onChange={handleEndDateChange} hideAddon />
      </div>
    </div>
  );
};

export default DatepickerRange;

import parse from 'html-react-parser';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { getSelectedProp } from '@components/PropSelector';
import { getImageObject } from '@helpers/api/objects';
import { getProperty } from '@helpers/api/properties';
import { searchAccommRsv } from '@helpers/api/reservations';
import { Property, Room } from '@models/properties';
import { AccommReservation, AccommRsvRoom, AccommRsvSearch } from '@models/reservations';
import { errorClsToMsg, hideHeader } from '@utils/funcs';

import './assets/registCardPrint.scss';

interface PrintAccommRsvRoom extends AccommRsvRoom {
  rsvId: string;
  checkInDate: string;
  checkOutDate: string;
}

interface RegistCardData {
  rsvId: string | null;
  guestRoomId?: number | null;
  checkInDate?: string;
  checkOutDate?: string;
  guestName: string;
  guestKana: string;
  birthDay: string;
  gender: 'Male' | 'Female';
  nationality: string;
  tel: string;
  mail: string;
  postCode: string;
  addressFull: string;
  directMailStatus: 'Yes' | 'No' | 'Unconfirmed';
  letterSent: boolean;
  emailSent: boolean;
  job?: string;
  addressPrefId?: number | null;
  signatureImage?: string;
  registCardImage?: string;
}

const DailyReserveRegistCardsPrint: React.FC = (props) => {
  const navigate = useNavigate();
  const [rsvData, setRsvData] = useState<AccommReservation[]>([]);
  const [registCardData, setRegistCardData] = useState<RegistCardData[]>([]);
  const [property, setProperty] = useState<Property>();
  const selectedProp = getSelectedProp();
  const location = useLocation();
  const today = moment().format('YYYY-MM-DD');

  const searchParams: AccommRsvSearch = useMemo(() => {
    const rawSearchParams = queryString.parse(location.search);
    return { ...rawSearchParams, propId: selectedProp ?? '', stayFrom: today, stayTo: today };
  }, [location.search, selectedProp, today]);

  const selectedBuildingId = useMemo(() => {
    const rawSearchParams = queryString.parse(location.search);
    return Number(rawSearchParams.selectedBuildingId);
  }, [location.search]);

  const [logo, setLogo] = useState<string | null>(null);

  useEffect(() => {
    if (selectedProp) {
      getProperty(selectedProp)
        .then((resp) => {
          setProperty(resp.data);
        })
        .catch((e) => console.log(errorClsToMsg(e)));
    }
  }, [selectedProp]);

  useEffect(() => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (typeof reader.result === 'string') {
        setLogo(reader.result);
      }
    });

    if (property?.logoUrl) {
      getImageObject(property.logoUrl).then((resp) => reader.readAsDataURL(resp.data));
    } else {
      setLogo(null);
    }
  }, [property?.logoUrl]);

  useEffect(() => {
    let guestData;
    if (selectedProp) {
      searchAccommRsv(searchParams ? searchParams : { propId: selectedProp }) // always use newest propId
        .then((resp) => {
          const tempRegistCardData: RegistCardData[] = [];
          let loadImg = 0;
          setRsvData(resp.data);
          const masterRooms: Room[] = [];
          if (property) {
            property.roomTypes.filter((x) => !x.isDeleted).forEach((x) => masterRooms.push(...x.rooms));
            const rooms: PrintAccommRsvRoom[] = [];
            _.flatten(
              resp.data.map((rsv: AccommReservation) =>
                rsv.roomList.map((rsvRoom) => ({
                  ...rsvRoom,
                  rsvId: rsv.id,
                  checkInDate: rsv.checkInDate,
                  checkOutDate: rsv.checkOutDate,
                }))
              )
            ).forEach((room: PrintAccommRsvRoom) => {
              const masterRoom = masterRooms.find((mRoom: Room) => mRoom.id === room.roomId);
              if (masterRoom?.buildingId === selectedBuildingId) {
                rooms.push(room);
              }
            });

            const toBase64 = (file: Blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
              });

            rooms.forEach(async (room: PrintAccommRsvRoom, roomIndex) => {
              if (room.listIdx === 0) {
                guestData = rsvData.find((rsv) => rsv.id === room.rsvId)?.guest;
              } else {
                guestData = room.guest || {};
              }

              tempRegistCardData.push({
                rsvId: room.rsvId,
                guestRoomId: room.roomId,
                checkInDate: room.checkInDate,
                checkOutDate: room.checkOutDate,
                guestName: room.guestName || guestData?.name || '',
                guestKana: guestData?.nameKana || '',
                birthDay: guestData?.birthday || '',
                gender: guestData?.gender || 'Male',
                nationality: guestData?.nationality || '',
                tel: guestData?.telNumber1 || '',
                mail: guestData?.email1 || '',
                postCode: guestData?.postalCode || '',
                addressFull: guestData?.addressFull || '',
                addressPrefId: guestData?.addressPrefId,
                directMailStatus: guestData?.directMail?.send || 'No',
                letterSent: guestData?.directMail?.letterSent ?? false,
                emailSent: guestData?.directMail?.emailSent ?? false,
                job: guestData?.companyName,
              });

              const signatureImage = room.signatureImage;
              const registCardImage = room.registCardImage;

              if (signatureImage) {
                const resp = await getImageObject(signatureImage);
                tempRegistCardData[roomIndex].signatureImage = String(await toBase64(resp.data));
              }

              if (registCardImage) {
                const resp = await getImageObject(registCardImage);
                tempRegistCardData[roomIndex].registCardImage = String(await toBase64(resp.data));
              }

              if (++loadImg === rooms.length) {
                setRegistCardData(tempRegistCardData);
              }
            });
          }
        })
        .catch((e) => console.log(errorClsToMsg(e)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property, searchParams, selectedBuildingId, selectedProp]);

  const refRegistCardPrint = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => refRegistCardPrint.current,
  });

  return (
    <>
      {parse(hideHeader)}
      {registCardData.length > 0 ? (
        <div ref={refRegistCardPrint} className="regist-cards-container">
          {_.values(_.groupBy(registCardData, (item: RegistCardData) => item.rsvId)).map((cards, index) => (
            <Card key={index} className="accomm-card-print mb-0">
              <Card.Header>
                <Row style={{ width: '100%' }}>
                  <Col xs={7} sm={4}>
                    <Row>
                      <Col xs={12} className="mb-2">
                        <h3 className="text-dark">宿泊カード/Registration Card</h3>
                      </Col>

                      <Col xs={12}>
                        <span>チェックイン/Check in：{cards[0]?.checkInDate}</span>
                      </Col>

                      <Col xs={12}>
                        <span>チェックアウト/Check out：{cards[0]?.checkOutDate}</span>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={5} sm={4}>
                    {logo ? (
                      <img className="w-100" src={logo} alt="property-logo"></img>
                    ) : (
                      <div className="personal-logo">LOGO</div>
                    )}
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body>
                {cards.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.registCardImage ? (
                        <div>
                          <img className="w-100" src={item.registCardImage} alt="regist card img"></img>
                        </div>
                      ) : (
                        <div className={index > 0 ? 'mt-5' : 'mt-1'} key={index}>
                          <table className="table">
                            <tbody>
                              <tr>
                                <td style={{ width: 140 }}>
                                  <div>フリガナ</div>
                                </td>
                                <td style={{ minWidth: 170 }}>
                                  <div className="px-3 text-start">{item.guestKana}</div>
                                </td>
                                <td rowSpan={2}>
                                  <div>性別</div>
                                  <div>Gender</div>
                                </td>
                                <td rowSpan={2}>
                                  <div>{item.gender === 'Male' ? '男' : '女'}</div>
                                </td>
                                <td rowSpan={2}>
                                  <div>生年月日</div>
                                  <div>Date of birth</div>
                                </td>
                                <td rowSpan={2} style={{ minWidth: 100 }}>
                                  <div>{moment(item.birthDay).format('YYYY/MM/DD')}</div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div>お名前</div>
                                  <div>Name</div>
                                </td>
                                <td>
                                  <div className="px-3 text-start">{item.guestName}</div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div>住所</div>
                                  <div>Address</div>
                                </td>
                                <td colSpan={5}>
                                  <div className="px-3 text-start">{item.addressFull}</div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div>電話番号</div>
                                  <div>Tell</div>
                                </td>
                                <td colSpan={5}>
                                  <div className="px-3 text-start">{item.tel}</div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div>メールアドレス</div>
                                  <div>E-mall</div>
                                </td>
                                <td colSpan={5}>
                                  <div className="px-3 text-start">{item.mail}</div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div>職業</div>
                                  <div>Job</div>
                                </td>
                                <td colSpan={5}>
                                  <div className="px-3 text-start">{item.job}</div>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <Row className="justify-content-end g-0 mt-5 text-dark">
                            <Col className="border-bottom-2 px-2" xs={6}>
                              <div className="text-center">
                                {item.signatureImage ? (
                                  <img src={item.signatureImage} alt="signature img" height={150}></img>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="fw-bold px-2">署名</div>
                              <div className="signature mb-1">signature</div>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </div>
                  );
                })}
              </Card.Body>
            </Card>
          ))}
        </div>
      ) : (
        <></>
      )}

      {registCardData.length > 0 ? (
        <div className="form-action text-center my-4">
          <Button
            onClick={() => {
              handlePrint();
            }}
            variant="success"
            className="btn btn-save btn-common me-2"
          >
            印 刷
          </Button>

          <Button
            onClick={() => navigate('/reservations/accommodation')}
            variant="secondary"
            className="btn btn-cancel btn-common"
          >
            キャンセル
          </Button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DailyReserveRegistCardsPrint;

import { accommRsvEditorActions } from './constants';

import { AccommReservation } from '@models/reservations';

type AccommRsvEditorActionTypes = {
  type: string;
  payload: AccommReservation;
};

export const setAccommRsv = (accommRsv: AccommReservation): AccommRsvEditorActionTypes => ({
  type: accommRsvEditorActions.SET_RSV_VALUE,
  payload: accommRsv,
});

export type { AccommRsvEditorActionTypes };

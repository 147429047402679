import ReportLayout from '@layouts/ReportLayout';
import TableReportLayout from '@layouts/TableReportLayout';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

import MealTable from './table';

import Spinner from '@components/Spinner';
import { getProperty } from '@helpers/api/properties';
import { searchAccommRsv } from '@helpers/api/reservations';
import { Property } from '@models/properties';
import { AccommReservation } from '@models/reservations';
import { useAppSelector } from '@redux/hooks';

import './main.scss';

const RoomAllocationTables: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [currentDayRsvs, setCurrentDayRsvs] = useState<AccommReservation[]>([]);
  const [buttonClicked, setButtonClicked] = useState(true);

  const [property, setProperty] = useState<Property>();

  const selectedProp = useAppSelector((root) => root.Prop.value);

  const fetchRsv = useCallback(() => {
    if (!buttonClicked) return; // wait button to be clicked before refresh
    if (!selectedProp) return; // type guard

    const selectedDateISOStr = moment(selectedDate).format('YYYY-MM-DD');

    searchAccommRsv({
      propId: selectedProp,
      stayFrom: selectedDateISOStr,
      stayTo: selectedDateISOStr,
    }).then((resp) => {
      if (resp.status === 200) {
        setCurrentDayRsvs(resp.data);
        setButtonClicked(false);
      }
    });
  }, [selectedDate, selectedProp, buttonClicked]);

  // call fetchRsv on inital rendering
  useEffect(() => fetchRsv());

  useEffect(() => {
    if (!selectedProp) return; // type guard

    getProperty(selectedProp).then((resp) => {
      if (resp.status === 200) {
        setProperty(resp.data);
      }
    });
  }, [selectedProp]);

  const handleChange = (date: Date | null) => {
    if (date) setSelectedDate(date);
  };

  const handleFilterBtn = () => {
    setButtonClicked(true);
  };

  if (!property)
    return (
      <div className="text-center mt-4 mb-4">
        <Spinner />
      </div>
    );

  return (
    <ReportLayout
      title="部屋割表"
      selectedDate={selectedDate}
      onChange={handleChange}
      handleClickFilter={handleFilterBtn}
      tablePagePrint={<MealTable rsvs={currentDayRsvs} property={property} />}
    >
      {buttonClicked ? (
        <div className="text-center mt-4 mb-4">
          <Spinner />
        </div>
      ) : (
        <TableReportLayout>
          <MealTable rsvs={currentDayRsvs} property={property} />
        </TableReportLayout>
      )}
    </ReportLayout>
  );
};

export default RoomAllocationTables;

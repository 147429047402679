import { combineReducers } from 'redux';

import AccommRsvBanquet from './accommRsvBanquet/reducers';
import AccommRsvEditor from './accommRsvEditor/reducers';
import AccommRsvInDayTrip from './accommRsvInDayTrip/reducers';
import AccommRsvList from './accommRsvList/reducers';
import AccommRsvPlan from './accommRsvPlan/reducers';
import Auth from './auth/reducers';
import Guests from './guests/reducers';
import InDayTripPlans from './in_day_trip_plans/reducers';
import Layout from './layout/reducers';
import Prop from './prop/reducers';
import Property from './property/reducers';

export default combineReducers({
  Auth,
  Layout,
  Prop,
  AccommRsvList,
  AccommRsvEditor,
  AccommRsvInDayTrip,
  Property,
  Guests,
  InDayTripPlans,
  AccommRsvBanquet,
  AccommRsvPlan,
});

import { InDayTripPlanActions } from './constants';

import { InDayTripPlan } from '@models/in_day_trip_reservations';

type InDayTripPlanTypes = {
  type: string;
  payload: InDayTripPlan[];
};

export const setInDayTripPlans = (inDayTripPlans: InDayTripPlan[]): InDayTripPlanTypes => ({
  type: InDayTripPlanActions.SET_VALUE,
  payload: inDayTripPlans,
});

export type { InDayTripPlanTypes };

import { InDayTripPlanTypes } from './actions';
import { InDayTripPlanActions } from './constants';

import { InDayTripPlan } from '@models/in_day_trip_reservations';

const INIT_STATE = {
  inDayTripPlans: [],
};

type InDayTripPlanState = {
  inDayTripPlans: InDayTripPlan[];
};

const InDayTripPlanReducer = (
  state: InDayTripPlanState = INIT_STATE,
  action: InDayTripPlanTypes
): InDayTripPlanState => {
  switch (action.type) {
    case InDayTripPlanActions.SET_VALUE:
      return {
        ...action.payload,
        inDayTripPlans: [...action.payload],
      };
    default:
      return { ...state };
  }
};

export default InDayTripPlanReducer;
export type { InDayTripPlanState };

import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ja';

import { RoomSale } from '@models/reports';

const summaryDailySaleCalculate = (saleItems: RoomSale[] | undefined, property: string) => {
  if (property === 'totalStay') {
    let childrenTotal = 0;
    let adultTotal = 0;
    const groupedSaleItems = _.groupBy(saleItems, 'roomName');
    Object.keys(groupedSaleItems).forEach((roomId) => {
      childrenTotal += groupedSaleItems[roomId][0].totalStay.child;
      adultTotal += groupedSaleItems[roomId][0].totalStay.adult;
    });
    return `${adultTotal}/${childrenTotal}`;
  } else if (property === 'totalPrice') {
    let propertyTotal = 0;
    saleItems?.forEach((roomItem: RoomSale) => {
      propertyTotal += roomItem.bathTax + roomItem.additionalPrice + roomItem.planPrice;
    });
    return propertyTotal;
  } else {
    let propertyTotal = 0;
    saleItems?.forEach((roomItem: RoomSale) => {
      propertyTotal += roomItem[property as keyof RoomSale] as number;
    });
    return propertyTotal;
  }
};

const formatedDayOfMonth = (dayNumber: number, selectedDate: Date | null) => {
  const dateString = `${moment(selectedDate).format('YYYY-MM')}-${dayNumber}`;
  const dayName = moment(dateString, 'YYYY-MM-DD').format('dd');
  return `${dayNumber}日(${dayName})`;
};

const dateToString = (date: Date | null, separator: string | null = null, showWeekDay: boolean | null = false) => {
  if (!date) return '';
  if (!separator) separator = '-';
  return moment(date)
    .locale('ja')
    .format(`YYYY${separator}MM${separator}DD${showWeekDay ? ' (dd)' : ''}`);
};

const reformatDateToString = (date: Date, typeDate: string) => {
  return date ? moment(date).format(typeDate) : '';
};

export { summaryDailySaleCalculate, formatedDayOfMonth, dateToString, reformatDateToString };

import React, { ReactNode, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';

import { ComponentToPrint } from './ComponentToPrint';

interface PagePrintProps {
  tablePagePrint: ReactNode;
}

const PagePrint: React.FC<PagePrintProps> = ({ tablePagePrint }) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div className="d-none">
        <ComponentToPrint ref={componentRef} tablePagePrint={tablePagePrint} />
      </div>
      <div>
        <Button size="sm" className="custom-btn" onClick={handlePrint}>
          印刷
        </Button>
      </div>
    </>
  );
};

export default PagePrint;

import { AccommRsvBanquetActionTypes } from './actions';
import { accommRsvBanquetActions } from './constants';

import { BanquetReservation } from '@models/banquet_reservations';

const INIT_STATE = {
  accommRsvBanquet: {
    id: '',
    propId: '',
    banquetName: '',
    totalPeople: undefined,
    staffName: '',
    guestId: '',
    guestName: '',
    bookingDate: '',
    startTime: '',
    endTime: '',
    banquetVenueId: undefined,
    rsvStatus: 'NotArrived',
    paymentSettled: false,
    lostItem: {
      hasLostItem: false,
      imageObject: null,
      memo: '',
    },
    itemRecords: [],
    planRecords: [],
    depositRecords: [],
    taxRecords: [],
    memo: '',
    operateHistory: [],
    createTime: '',
    updateTime: '',
  },
};

type AccommRsvBanquetState = {
  accommRsvBanquet: BanquetReservation;
};

const AccommRsvBanquet = (
  state: AccommRsvBanquetState = INIT_STATE,
  action: AccommRsvBanquetActionTypes
): AccommRsvBanquetState => {
  switch (action.type) {
    case accommRsvBanquetActions.SET_RSV_VALUE:
      return {
        ...action.payload,
        accommRsvBanquet: { ...action.payload },
      };
    default:
      return { ...state };
  }
};

export { INIT_STATE };
export default AccommRsvBanquet;
export type { AccommRsvBanquetState };

import { accommRsvBanquetActions } from './constants';
import { INIT_STATE } from './reducers';

import { BanquetReservation } from '@models/banquet_reservations';

type AccommRsvBanquetActionTypes = {
  type: string;
  payload: BanquetReservation;
};

export const setRsvBanquet = (accommRsvBanquet: BanquetReservation): AccommRsvBanquetActionTypes => ({
  type: accommRsvBanquetActions.SET_RSV_VALUE,
  payload: accommRsvBanquet,
});

export const reSetRsvBanquet = (): AccommRsvBanquetActionTypes => {
  return {
    type: accommRsvBanquetActions.SET_RSV_VALUE,
    payload: INIT_STATE.accommRsvBanquet,
  };
};

export type { AccommRsvBanquetActionTypes };

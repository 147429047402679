import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import './assets/styles.scss';
interface TableReportLayoutProps {
  children: ReactNode;
  md?: number;
  xs?: number;
  sm?: number;
  xl?: number;
  lg?: number;
  xxl?: number;
  tableTitle?: string;
  noMaxHeight?: boolean;
}
const TableReportLayout: React.FC<TableReportLayoutProps> = ({
  children,
  md,
  xs,
  sm,
  xl,
  xxl,
  lg,
  tableTitle,
  noMaxHeight,
}) => {
  return (
    <Row>
      <Col md={md} xs={xs} sm={sm} xl={xl} xxl={xxl} lg={lg} className="flex-fill">
        <Card className="card-container px-3">
          <Card.Body
            className={classNames(`card-item-max-h pt-4 overflow-auto ${noMaxHeight ? 'card-item-n-max-h' : ''}`)}
          >
            <div>{tableTitle}</div>
            {children}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
export default TableReportLayout;

import React from 'react';
import { Row, Col } from 'react-bootstrap';

type PageTitleProps = {
  title: string;
  additionalClasses?: string;
};

const PageTitle: React.FC<PageTitleProps> = (props) => {
  return (
    <Row className={props.additionalClasses}>
      <Col>
        <div className="page-title-box">
          <div className="page-title-right d-inline-flex">{props.children}</div>
          <h4 className="page-title">{props.title}</h4>
        </div>
      </Col>
    </Row>
  );
};

export default PageTitle;

import { AxiosResponse } from 'axios';

import { APICore } from './apiCore';

import { User, UserCreate, UserPatch } from '@models/users';

const api = new APICore();

function createUser(data: UserCreate): Promise<AxiosResponse<User>> {
  return api.postJSON('/users', data);
}

function getSelf(): Promise<AxiosResponse<User>> {
  return api.get('/users/self');
}

function getStaffs(): Promise<AxiosResponse<User[]>> {
  return api.get('/users/staffs');
}

function getUser(userId: string): Promise<AxiosResponse<User>> {
  return api.get(`/users/${userId}`);
}

function updateUser(userId: string, data: UserPatch): Promise<AxiosResponse<User>> {
  return api.patchJSON(`/users/${userId}`, data);
}

function disableUser(userId: string): Promise<AxiosResponse<User>> {
  return api.patchJSON(`/users/${userId}`, { isDisabled: true });
}

function deleteUser(userId: string): Promise<AxiosResponse<User>> {
  return api.delete(`/users/${userId}`);
}

export { getSelf, getStaffs, getUser, createUser, updateUser, disableUser, deleteUser };

import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import notFoundImg from '@assets/images/startman.svg';

interface ErrorCardProps {
  title: string;
}

const ErrorCard: React.FC<ErrorCardProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <Row className="justify-content-center">
          <Col lg={4}>
            <div className="text-center">
              <img src={notFoundImg} height="90" alt="" />
              <h4 className="text-uppercase text-danger mt-3">{t(props.title)}</h4>

              <Link className="btn btn-info mt-3" to="/">
                <i className="mdi mdi-reply"></i> ホームへ戻る
              </Link>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ErrorCard;

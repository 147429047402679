import React from 'react';
import { Table } from 'react-bootstrap';

import { Property } from '@models/properties';
import { AccommReservation } from '@models/reservations';
import { dateToString } from '@utils/reports';

const tableHeader = ['部屋番号', '利用者名', '人数　大/高/低/A/B/C/D', 'プラン名', '送迎', '備考'];

interface RoomAllocationTableProps {
  rsvs: AccommReservation[];
  property: Property;
}

const RoomAllocationTable: React.FC<RoomAllocationTableProps> = ({ rsvs, property }) => {
  return (
    <div>
      <div>
        <div className="float-left">
          <b>部屋割表</b>
        </div>
        <div className="float-right">
          <b>{dateToString(new Date(), '/', true)}</b>
        </div>
      </div>
      <Table>
        <thead>
          <tr>
            {tableHeader.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rsvs
            .map((rsv, rsvIndex) => {
              const accommPlan = property.accommPlans.find((x) => x.id === rsv.planId);
              return rsv.roomList.map((room, roomIndex) => (
                <tr key={`${rsvIndex}-${roomIndex}-1`}>
                  <td>{room.roomName}</td>
                  <td>{room.guestName}</td>
                  <td>
                    {room.paxCount.adult}/{room.paxCount.child1}/{room.paxCount.child2}/{room.paxCount.child3}/
                    {room.paxCount.child4}/{room.paxCount.child5}/{room.paxCount.child6}
                  </td>
                  <td>{accommPlan?.name}</td>
                  <td>
                    <input checked={rsv.howToCome === 'PickUp'} type="checkbox" />
                  </td>
                  <td style={{ whiteSpace: 'pre-wrap' }}>{rsv.comment}</td>
                </tr>
              ));
            })
            .flat()}
        </tbody>
      </Table>
    </div>
  );
};

export default RoomAllocationTable;

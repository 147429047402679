import axios from 'axios';
import React, { Suspense, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import * as layoutConstants from '../constants/layout';
import DefaultLayout from '../layouts/Default';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal/';
import VerticalLayout from '../layouts/Vertical';

import ErrorCard from '@components/ErrorCard';
import Loader from '@components/Loader';
import { getLocalUser, isUserAuthenticated, setLocalUser } from '@helpers/api/apiCore';
import { getSelf } from '@helpers/api/users';
import NotFound from '@pages/errors/NotFound';
import { useAppSelector } from '@redux/hooks';

import { authProtectedFlattenRoutes, publicFlattenRoutes } from './index';

const Maintenance = React.lazy(() => import('../pages/errors/Maintenance'));

const AppRoutes: React.FC = (props) => {
  const [underMaintenance, setUnderMaintenance] = useState(false);
  const [loading, setLoading] = useState(true);
  const [logedinUser, setLogedinUser] = useState(getLocalUser());

  const { layout } = useAppSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (layout.layoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case layoutConstants.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  const Layout = getLayout();
  const isAuthed = isUserAuthenticated();

  useEffect(() => {
    const pmsStatus = process.env.REACT_APP_PMS_STATUS;
    if (pmsStatus) {
      axios
        .get(pmsStatus)
        .then((resp) => {
          if (resp.data) setUnderMaintenance(resp.data.underMaintenance);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (isAuthed && !logedinUser) {
      setLoading(true);
      getSelf().then((response) => {
        setLogedinUser(response.data);
        setLocalUser(response.data);
        setLoading(false);
      });
    }
  }, [isAuthed, logedinUser]);

  if (loading || underMaintenance)
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={
              <DefaultLayout layout={layout} {...props}>
                {loading ? <Loader /> : underMaintenance && <Maintenance />}
              </DefaultLayout>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    );

  return (
    <BrowserRouter>
      <Routes>
        {publicFlattenRoutes
          .filter((route) => !route.children)
          .map((route) => (
            <Route
              key={null}
              path={route.path}
              element={
                <DefaultLayout layout={layout} {...props}>
                  <route.component {...props} />
                </DefaultLayout>
              }
            />
          ))}

        {authProtectedFlattenRoutes
          .filter((route) => !route.children)
          .filter((route) => (route.menuId ? logedinUser?.pagePrivileges.includes(route.menuId) : true))
          .map((route) => {
            if (isAuthed)
              return (
                <Route
                  key={null}
                  path={route.path}
                  element={
                    <Layout {...props}>
                      <route.component {...props} />
                    </Layout>
                  }
                />
              );
            else
              return (
                <Route
                  key={null}
                  path={route.path}
                  element={<Navigate to={{ pathname: '/login' }} state={{ form: window.location.pathname }} />}
                />
              );
          })}
        {isAuthed ? (
          <Route
            path="*"
            element={
              <Layout {...props}>
                <Row className="mt-4">
                  <Col>
                    <ErrorCard title="Page Not Found" />
                  </Col>
                </Row>
              </Layout>
            }
          ></Route>
        ) : (
          <Route
            path="*"
            element={<Suspense fallback={<Loader />}>{!loading && !underMaintenance && <NotFound />}</Suspense>}
          />
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;

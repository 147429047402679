import axios, { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';

import config from '../../config';

import { ServerError, APIDefinedErrorCls, ClientErrorCls } from '@models/errors';
import { User } from '@models/users';
import { Json } from '@utils/types';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_ENDPOINT;

// intercepting to capture errors
// see https://axios-http.com/docs/interceptors
axios.interceptors.response.use(
  (response) => response,
  // type error.response.data with ServerError
  (error: AxiosError<ServerError>) => {
    // any status codes that falls outside the range of 2xx
    // cause this function to trigger
    if (error.response) {
      const detail = error.response.data.detail;
      // API defined errors
      if ('code' in detail) {
        if (detail.code === 'E03') {
          setLocalToken(null);
          setLocalUser(null);
          window.location.href = '/logout'; // logout user if token is invalid
        }
        return Promise.reject(new APIDefinedErrorCls(detail));
      }
    } else {
      // client error like no network do not have response
      return Promise.reject(new ClientErrorCls(error.message, error.code));
    }

    // if we failed dealing with the error, reject with origin error
    return Promise.reject(error);
  }
);

const LOCAL_TOKEN_KEY = 'hitouToken';
const LOCAL_USER_KEY = 'hitouUser';

const setAuthorization = (token: string | null) => {
  if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  else delete axios.defaults.headers.common['Authorization'];
};

const getLocalToken = () => {
  return localStorage.getItem(LOCAL_TOKEN_KEY);
};

const setLocalToken = (token: string | null) => {
  if (token) localStorage.setItem(LOCAL_TOKEN_KEY, token);
  else localStorage.removeItem(LOCAL_TOKEN_KEY);
  setAuthorization(token);
};

const getLocalUser = () => {
  const user = sessionStorage.getItem(LOCAL_USER_KEY);
  return user ? (JSON.parse(user) as User) : null;
};

const setLocalUser = (data: User | null) => {
  if (data) sessionStorage.setItem(LOCAL_USER_KEY, JSON.stringify(data));
  else sessionStorage.removeItem(LOCAL_USER_KEY);
};

type DecodedToken = { sub: string; exp: number };

const isUserAuthenticated = () => {
  const token = getLocalToken();
  if (!token) return false;

  // decode JWT token
  let decoded: DecodedToken;
  try {
    decoded = jwtDecode(token);
  } catch {
    return false;
  }

  // verify whether expired
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    return false;
  } else {
    return true;
  }
};

class APICore {
  get = (url: string, params?: object) => {
    return axios.get(url, { params });
  };

  getBlob = (url: string, params?: object) => {
    return axios.get(url, { responseType: 'blob', params });
  };

  getMultiple = (urls: string[], params?: object) => {
    const reqs = [];
    for (const url of urls) {
      reqs.push(axios.get(url, { params }));
    }
    return axios.all(reqs);
  };

  postJSON = (url: string, data: Json, params?: object) => {
    return axios.post(url, data, { params });
  };

  postForm = (url: string, data: Record<string, string | Blob>) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers.post,
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(url, formData, config);
  };

  putJSON = (url: string, data: Json) => {
    return axios.put(url, data);
  };

  patchJSON = (url: string, data: Json) => {
    return axios.patch(url, data);
  };

  patchForm = (url: string, data: Record<string, string | Blob>) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers.patch,
        'content-type': 'multipart/form-data',
      },
    };
    return axios.patch(url, formData, config);
  };

  delete = (url: string, data?: Json) => {
    return axios.delete(url, { data });
  };
}

/*
Check if token available in localStorage
*/
const token = localStorage.getItem(LOCAL_TOKEN_KEY);
if (token) {
  setAuthorization(token);
}

export { APICore, getLocalToken, getLocalUser, isUserAuthenticated, setLocalToken, setLocalUser };

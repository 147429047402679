import { AuthToken } from '../../models/auth';
import { RequestError } from '../../models/errors';
import { AuthActionTypes } from './actions';
import { AuthActions } from './constants';

import { isUserAuthenticated } from '@helpers/api/apiCore';

const INIT_STATE = {
  loading: false,
  token: null,
  userLoggedIn: isUserAuthenticated(),
};

type AuthState = {
  error?: RequestError;
  loading: boolean;
  token: AuthToken | null;
  userLoggedIn?: boolean;
  userLogout?: boolean;
};

const Auth = (state: AuthState = INIT_STATE, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case AuthActions.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthActions.LOGIN_USER: {
          // just a type guard. to solve this problem elegantly, we may need
          // write types for each action and combine them in AuthActionTypes
          if (action.payload.token === undefined) throw new Error('Token not given');
          return {
            ...state,
            loading: false,
            token: action.payload.token,
            userLoggedIn: true,
          };
        }
        case AuthActions.LOGOUT_USER: {
          return {
            ...state,
            loading: false,
            userLogout: true,
            userLoggedIn: false,
          };
        }
        default:
          return { ...state };
      }

    case AuthActions.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthActions.LOGIN_USER: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            userLoggedIn: false,
          };
        }
        default:
          return { ...state };
      }

    case AuthActions.LOGIN_USER:
      return { ...state, loading: true, userLoggedIn: false };
    case AuthActions.LOGOUT_USER:
      return { ...state, loading: true, userLogout: false };
    case AuthActions.RESET:
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default Auth;
export type { AuthState };

import * as layoutConstants from '../../constants/layout';
import { LayoutActionTypes } from './actions';
import { LayoutActions } from './constants';

const INIT_STATE = {
  layoutType: layoutConstants.LAYOUT_HORIZONTAL,
  layoutWidth: layoutConstants.LAYOUT_WIDTH_FLUID,
  leftSideBarTheme: layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT,
  leftSideBarType: layoutConstants.LEFT_SIDEBAR_TYPE_FIXED,
  showRightSidebar: false,
};

type LayoutState = {
  layoutType: string;
  layoutWidth: string;
  leftSideBarTheme: string;
  leftSideBarType: string;
  showRightSidebar: boolean;
};

const Layout = (state: LayoutState = INIT_STATE, action: LayoutActionTypes): LayoutState => {
  switch (action.type) {
    case LayoutActions.CHANGE_LAYOUT:
      if (!action.payload) throw new Error('Invalid action');
      return {
        ...state,
        layoutType: action.payload,
      };
    case LayoutActions.CHANGE_LAYOUT_WIDTH:
      if (!action.payload) throw new Error('Invalid action');
      return {
        ...state,
        layoutWidth: action.payload,
      };
    case LayoutActions.CHANGE_SIDEBAR_THEME:
      if (!action.payload) throw new Error('Invalid action');
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };
    case LayoutActions.CHANGE_SIDEBAR_TYPE:
      if (!action.payload) throw new Error('Invalid action');
      return {
        ...state,
        leftSideBarType: action.payload,
      };
    case LayoutActions.TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar,
      };
    case LayoutActions.SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true,
      };
    case LayoutActions.HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false,
      };
    default:
      return state;
  }
};

export default Layout;
export type { LayoutState };

import { LayoutActions } from './constants';

type LayoutActionTypes = { type: string; payload: string | null };

export const changeLayout = (layout: string): LayoutActionTypes => ({
  type: LayoutActions.CHANGE_LAYOUT,
  payload: layout,
});

export const changeLayoutWidth = (width: string): LayoutActionTypes => ({
  type: LayoutActions.CHANGE_LAYOUT_WIDTH,
  payload: width,
});

export const changeSidebarTheme = (theme: string): LayoutActionTypes => ({
  type: LayoutActions.CHANGE_SIDEBAR_THEME,
  payload: theme,
});

export const changeSidebarType = (sidebarType: string): LayoutActionTypes => ({
  type: LayoutActions.CHANGE_SIDEBAR_TYPE,
  payload: sidebarType,
});

export const toggleRightSidebar = (): LayoutActionTypes => ({
  type: LayoutActions.TOGGLE_RIGHT_SIDEBAR,
  payload: null,
});

export const showRightSidebar = (): LayoutActionTypes => ({
  type: LayoutActions.SHOW_RIGHT_SIDEBAR,
  payload: null,
});

export const hideRightSidebar = (): LayoutActionTypes => ({
  type: LayoutActions.HIDE_RIGHT_SIDEBAR,
  payload: null,
});

export type { LayoutActionTypes };

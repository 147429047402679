import { AxiosResponse } from 'axios';

import { APICore } from './apiCore';

import { AuthToken } from '@models/auth';

const api = new APICore();

function login(params: { username: string; password: string }): Promise<AxiosResponse<AuthToken>> {
  return api.postForm('/auth/login', params);
}

function forgetPass(params: { email: string }): Promise<AxiosResponse<{ status: string }>> {
  return api.get('/auth/forget-password', params);
}

function resetPass(data: { token: string; password: string }): Promise<AxiosResponse<{ status: string }>> {
  return api.postJSON('/auth/reset-password', data);
}

export { login, forgetPass, resetPass };

import { AxiosResponse } from 'axios';

import { APICore } from './apiCore';

import { AccommReservation, AccommRsvCreate, AccommRsvPatch, AccommRsvSearch } from '@models/reservations';

const api = new APICore();

function getAccommRsv(accommRsvId: string): Promise<AxiosResponse<AccommReservation>> {
  return api.get(`/reservations/accommodation/${accommRsvId}`);
}

function searchAccommRsv(conditions: AccommRsvSearch): Promise<AxiosResponse<AccommReservation[]>> {
  return api.postJSON('/reservations/accommodation/search', conditions);
}

function createAccommRsv(accommRsv: AccommRsvCreate): Promise<AxiosResponse<AccommReservation>> {
  return api.postJSON('/reservations/accommodation', accommRsv);
}

function updateAccommRsv(accommRsvId: string, accommRsv: AccommRsvPatch): Promise<AxiosResponse<AccommReservation>> {
  return api.patchJSON(`/reservations/accommodation/${accommRsvId}`, accommRsv);
}

function bulkUpdateSettle(targetRsv: string[], paymentSettled: boolean): Promise<AxiosResponse<AccommReservation>> {
  return api.postJSON('/reservations/accommodation/bulk-update-settled', targetRsv, {
    payment_settled: paymentSettled,
  });
}

export { getAccommRsv, createAccommRsv, updateAccommRsv, searchAccommRsv, bulkUpdateSettle };

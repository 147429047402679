import React, { ReactNode } from 'react';

interface Props {
  tablePagePrint: ReactNode;
}

export class ComponentToPrint extends React.PureComponent<Props> {
  render() {
    return <div>{this.props.tablePagePrint}</div>;
  }
}

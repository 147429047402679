import { AxiosResponse } from 'axios';
import * as humps from 'humps';

import { APICore } from './apiCore';

import {
  Property,
  PropertyCreate,
  PropertyPatch,
  AccommodationPlanSearch,
  AccommPlan,
  MealPlan,
  MealPlanSearch,
  BanquetPlanSearch,
  BanquetPlan,
} from '@models/properties';

const api = new APICore();

function createProperty(data: PropertyCreate): Promise<AxiosResponse<Property>> {
  return api.postJSON('/properties', data);
}

function updateProperty(propId: string, data: PropertyPatch): Promise<AxiosResponse<Property>> {
  return api.patchJSON(`/properties/${propId}`, data);
}

function getAllMealPlans(propId: string, data: MealPlanSearch): Promise<AxiosResponse<MealPlan[]>> {
  return api.postJSON(`/properties/${propId}/meal-plans/search`, data);
}

function getProperty(propId: string): Promise<AxiosResponse<Property>> {
  return api.get(`/properties/${propId}`);
}

function getAllProperty(mergeStaff?: string): Promise<AxiosResponse<Property[]>> {
  const params = humps.decamelizeKeys({ mergeStaff });
  return mergeStaff ? api.get('/properties/all', params) : api.get('/properties/all');
}

function deleteProperty(propId: string): Promise<AxiosResponse<Property[]>> {
  return api.delete(`/properties/${propId}`);
}

function searchAccommodationPlan(
  id: string,
  conditions: AccommodationPlanSearch
): Promise<AxiosResponse<AccommPlan[]>> {
  return api.postJSON(`/properties/${id}/accommodation-plans/search`, conditions);
}

function searchBanquetPlan(id: string, conditions: BanquetPlanSearch): Promise<AxiosResponse<BanquetPlan[]>> {
  return api.postJSON(`/properties/${id}/banquet-plans/search`, conditions);
}

export {
  createProperty,
  getProperty,
  getAllProperty,
  updateProperty,
  deleteProperty,
  searchAccommodationPlan,
  getAllMealPlans,
  searchBanquetPlan,
};

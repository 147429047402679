import { AccommRsvListActions } from './constants';

import { AccommReservation } from '@models/reservations';

type AccommRsvListActionTypes = {
  type: string;
  payload: AccommReservation[];
};

export const setAccommRsvList = (accommRsv: AccommReservation[]): AccommRsvListActionTypes => ({
  type: AccommRsvListActions.SET_RSV_VALUE,
  payload: accommRsv,
});

export type { AccommRsvListActionTypes };

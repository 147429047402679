import { accommRsvPlanActions } from './constants';

import { AccommPlan } from '@models/properties';

type AccommRsvPlanActionTypes = {
  type: string;
  payload: AccommPlan;
};

export const setRsvPlan = (accommRsvPlan: AccommPlan): AccommRsvPlanActionTypes => ({
  type: accommRsvPlanActions.SET_RSV_VALUE,
  payload: accommRsvPlan,
});

export type { AccommRsvPlanActionTypes };

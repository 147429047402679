import { AccommRsvListActionTypes } from './actions';
import { AccommRsvListActions } from './constants';

import { AccommReservation } from '@models/reservations';

const INIT_STATE = { accommRsv: [] };

type AccommRsvListState = {
  accommRsv: AccommReservation[];
};

const AccommRsvList = (
  state: AccommRsvListState = INIT_STATE,
  action: AccommRsvListActionTypes
): AccommRsvListState => {
  switch (action.type) {
    case AccommRsvListActions.SET_RSV_VALUE:
      return {
        ...state,
        accommRsv: [...action.payload],
      };
    default:
      return { ...state };
  }
};

export default AccommRsvList;
export type { AccommRsvListState };

import { Dispatch } from 'react';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';

import { AccommRsvBanquetActionTypes } from './accommRsvBanquet/actions';
import { AccommRsvBanquetState } from './accommRsvBanquet/reducers';
import { AccommRsvEditorActionTypes } from './accommRsvEditor/actions';
import { AccommRsvEditorState } from './accommRsvEditor/reducers';
import { AccommRsvInDayTripActionTypes } from './accommRsvInDayTrip/actions';
import { AccommRsvInDayTripState } from './accommRsvInDayTrip/reducers';
import { AccommRsvListActionTypes } from './accommRsvList/actions';
import { AccommRsvListState } from './accommRsvList/reducers';
import { AccommRsvPlanActionTypes } from './accommRsvPlan/actions';
import { AccommRsvPlanState } from './accommRsvPlan/reducers';
import { AuthActionTypes } from './auth/actions';
import { GuestTypes } from './guests/actions';
import { InDayTripPlanTypes } from './in_day_trip_plans/actions';
import { LayoutActionTypes } from './layout/actions';
import { PropActionTypes } from './prop/actions';
import { PropertyTypes } from './property/actions';

import type { AuthState } from './auth/reducers';
import type { GuestState } from './guests/reducers';
import type { InDayTripPlanState } from './in_day_trip_plans/reducers';
import type { LayoutState } from './layout/reducers';
import type { PropState } from './prop/reducers';
import type { PropertyState } from './property/reducers';

type RootState = {
  Auth: AuthState;
  Layout: LayoutState;
  Prop: PropState;
  AccommRsvEditor: AccommRsvEditorState;
  AccommRsvList: AccommRsvListState;
  AccommRsvInDayTrip: AccommRsvInDayTripState;
  Property: PropertyState;
  Guests: GuestState;
  InDayTripPlans: InDayTripPlanState;
  AccommRsvBanquet: AccommRsvBanquetState;
  AccommRsvPlan: AccommRsvPlanState;
};

type AppDispatch =
  | AuthActionTypes
  | LayoutActionTypes
  | PropActionTypes
  | PropertyTypes
  | GuestTypes
  | InDayTripPlanTypes
  | AccommRsvEditorActionTypes
  | AccommRsvListActionTypes
  | AccommRsvInDayTripActionTypes
  | AccommRsvBanquetActionTypes
  | AccommRsvPlanActionTypes;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// see https://react-redux.js.org/using-react-redux/usage-with-typescript
export const useAppDispatch = () => useDispatch<Dispatch<AppDispatch>>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import { GuestTypes } from './actions';
import { GuestActions } from './constants';

import { Guest } from '@models/guests';

const INIT_STATE = {
  guests: [],
};

type GuestState = {
  guests: Guest[];
};

const GuestReducer = (state: GuestState = INIT_STATE, action: GuestTypes): GuestState => {
  switch (action.type) {
    case GuestActions.SET_VALUE:
      return {
        ...action.payload,
        guests: [...action.payload],
      };
    default:
      return { ...state };
  }
};

export default GuestReducer;
export type { GuestState };

import { PropActions } from './constants';

type PropActionTypes = {
  type: string;
  payload: {
    value: string | null;
  };
};

export const setPropValue = (value: string | null): PropActionTypes => ({
  type: PropActions.SET_PROP_VALUE,
  payload: { value },
});

export type { PropActionTypes };

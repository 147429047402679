import classNames from 'classnames';
import React from 'react';

type SpinnerProps = {
  className?: string;
  size?: 'lg' | 'md' | 'sm';
  type?: 'bordered' | 'grow';
  color?: string;
};

const Spinner: React.FC<SpinnerProps> = (props) => {
  const children = props.children || null;
  const color = props.color || 'secondary';
  const size = props.size || '';

  return (
    <div
      role="status"
      className={classNames(
        { 'spinner-border': props.type === 'bordered', 'spinner-grow': props.type === 'grow' },
        [`text-${color}`],
        { [`avatar-${size}|`]: size },
        props.className
      )}
    >
      {children}
    </div>
  );
};

Spinner.defaultProps = {
  type: 'bordered',
};

export default Spinner;

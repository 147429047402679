import { accommRsvInDayTripActions } from './constants';
import { INIT_STATE } from './reducers';

import { AccommReservation } from '@models/in_day_trip_reservations';

type AccommRsvInDayTripActionTypes = {
  type: string;
  payload: AccommReservation;
};

export const setAccommRsvInDayTrip = (accommRsvInDayTrip: AccommReservation): AccommRsvInDayTripActionTypes => ({
  type: accommRsvInDayTripActions.SET_RSV_VALUE,
  payload: accommRsvInDayTrip,
});

export const reSetAccommRsvInDayTrip = (): AccommRsvInDayTripActionTypes => {
  return {
    type: accommRsvInDayTripActions.SET_RSV_VALUE,
    payload: INIT_STATE.accommRsvInDayTrip,
  };
};

export type { AccommRsvInDayTripActionTypes };

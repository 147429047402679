import classNames from 'classnames';
import ja from 'date-fns/locale/ja';
import React, { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

registerLocale('ja', ja);

type DatepickerInputProps = {
  onClick?: () => void;
  value?: string;
  children?: React.ReactNode;
};

// see https://stackoverflow.com/questions/67992894
const DatepickerInputRender = (props: DatepickerInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
  return (
    <input
      className="form-control date"
      onChange={() => false} // to hide onChange not specified error in console
      onClick={props.onClick}
      ref={ref}
      type="text"
      value={props.value}
    />
  );
};
const DatepickerInput = forwardRef(DatepickerInputRender);

type DatepickerInputWithAddonProps = {
  onClick?: () => void;
  value?: string;
  children?: React.ReactNode;
};

const DatepickerInputWithAddonRender = (
  props: DatepickerInputWithAddonProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  return (
    <div className="input-group" ref={ref}>
      <input type="text" className="form-control bg-white" onClick={props.onClick} value={props.value} readOnly />
      <div className="input-group-append">
        <span className="input-group-text bg-primary border-primary text-white">
          <i className="mdi mdi-calendar-range font-13"></i>
        </span>
      </div>
    </div>
  );
};
const DatepickerInputWithAddon = forwardRef(DatepickerInputWithAddonRender);

type DatepickerProps = {
  hideAddon?: boolean;
  inline?: boolean;
  inputClass?: string;
  isClearable?: boolean;
  maxDate?: Date;
  minDate?: Date;
  monthsShown?: number;
  disabled?: boolean;
  onChange: (date: Date | null) => void;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeCaption?: string;
  timeIntervals?: number;
  value: Date | null;
  showMonthYearPicker?: boolean;
  showYearPicker?: boolean;
  dateFormat?: string;
  yearDropdownItemNumber?: number;
};

const Datepicker = (props: DatepickerProps) => {
  const input = (props.hideAddon || false) === true ? <DatepickerInput /> : <DatepickerInputWithAddon />;

  return (
    <>
      <DatePicker
        autoComplete="off"
        disabled={props.disabled}
        className={classNames('form-control', props.inputClass)}
        customInput={input}
        dateFormat={props.showTimeSelectOnly ? 'HH:mm' : props.dateFormat || 'yyyy/MM/dd'}
        dateFormatCalendar="yyyy MMM"
        inline={props.inline}
        isClearable={props.isClearable}
        locale="ja"
        maxDate={props.maxDate}
        minDate={props.minDate}
        monthsShown={props.monthsShown}
        onChange={(date) => props.onChange(date)}
        selected={props.value}
        showMonthDropdown
        showTimeSelect={props.showTimeSelect}
        showTimeSelectOnly={props.showTimeSelectOnly}
        showYearDropdown
        timeCaption={props.timeCaption}
        timeIntervals={props.timeIntervals}
        showMonthYearPicker={props.showMonthYearPicker}
        showYearPicker={props.showYearPicker}
        scrollableYearDropdown
        yearDropdownItemNumber={props.yearDropdownItemNumber}
      />
    </>
  );
};

export default Datepicker;

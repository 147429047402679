import React from 'react';
import { Button } from 'react-bootstrap';

import PagePrint from './PagePrint';

import DatePicker from '@components/Datepicker';
import DatepickerRange from '@components/DatepickerRange';
import PageTitle from '@components/PageTitle';

import './assets/styles.scss';

interface ReportLayoutProps {
  title: string;
  onChange?: (date: Date | null) => void;
  children: React.ReactNode;
  selectedDate?: Date | null;
  handleClickFilter?: () => void;
  tablePagePrint: React.ReactNode;
  hasDatepickerRange?: boolean;
  startDate?: Date;
  endDate?: Date;
  handleStartDateChange?: (date: Date | null) => void;
  handleEndDateChange?: (date: Date | null) => void;
  showMonthly?: boolean;
  dateFormat?: string;
  showYear?: boolean;
}

const ReportLayout: React.FC<ReportLayoutProps> = ({
  children,
  title,
  handleClickFilter,
  tablePagePrint,
  hasDatepickerRange,
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  onChange = () => false,
  selectedDate = null,
  showMonthly,
  dateFormat,
  showYear,
}) => {
  return (
    <div className="report-layout">
      <PageTitle title={title} />
      <div className="mb-2 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {hasDatepickerRange ? (
            <div className="">
              <div>
                <DatepickerRange
                  startDate={startDate}
                  endDate={endDate}
                  handleStartDateChange={handleStartDateChange}
                  handleEndDateChange={handleEndDateChange}
                />
              </div>
            </div>
          ) : (
            <div className="w-auto">
              <DatePicker
                onChange={onChange}
                showMonthYearPicker={showMonthly}
                showYearPicker={showYear}
                value={selectedDate}
                dateFormat={dateFormat ? dateFormat : 'yyyy-MM-dd (E)'}
                hideAddon
              />
            </div>
          )}
          <div className="mx-4">
            <Button type="submit" size="sm" className="custom-btn" onClick={handleClickFilter}>
              表示
            </Button>
          </div>
        </div>
      </div>
      <div className="mb-2">
        {startDate && endDate && startDate > endDate && (
          <p className="error">開始日は終了日より前である必要があります。</p>
        )}
      </div>
      {children}
      <div className="d-flex justify-content-end my-3">
        <PagePrint tablePagePrint={tablePagePrint} />
      </div>
    </div>
  );
};

export default ReportLayout;

import { PropertyTypes } from './actions';
import { PropertyActions } from './constants';

import { Property } from '@models/properties';

type PropertyState = {
  property: Property;
};

const INIT_STATE: PropertyState = {
  property: {
    id: '',
    createTime: '',
    hitouStatus: {
      lastSync: null,
      syncStatus: 'Normal',
    },
    notifications: [],
    accommPlans: [],
    mealPlans: [],
    accommSalesItems: [],
    address: '',
    agencies: [],
    banquetPlans: [],
    banquetSalesItems: [],
    buildings: [],
    depositItems: [],
    mealVenues: [],
    name: '',
    roomTypes: [],
    rankTypes: [],
    taxTypes: [],
    telNumber: '',
    logoUrl: null,
    bizOffDates: [],
    isDeleted: false,
    guestTypes: [],
    pickupMethods: [],
    banquetVenues: [],
    hitouKey: '',
  },
};

const PropertyReducer = (state: PropertyState = INIT_STATE, action: PropertyTypes): PropertyState => {
  switch (action.type) {
    case PropertyActions.SET_PROPERTY_VALUE:
      return {
        ...action.payload,
        property: { ...action.payload },
      };
    default:
      return { ...state };
  }
};

export default PropertyReducer;
export type { PropertyState };

import _ from 'lodash';
import moment from 'moment';

import { APIDefinedErrorCls, ClientErrorCls, RequestError } from '@models/errors';
import type { SyncStatus, AccommPlan, Room, Property } from '@models/properties';
import type {
  AccommReservation,
  AccommRsvRoom,
  AccommRsvStatus,
  AccommRsvType,
  ItemRecord,
  PlanRecord,
  RsvTableColumn,
} from '@models/reservations';

const errorClsToMsg = (error: Error) => {
  if (error instanceof ClientErrorCls) return error.message;
  if (error instanceof APIDefinedErrorCls) return error.detail.message;
  return 'An unknown error occurred';
};

const errorToMsg = (error: RequestError) => {
  return 'detail' in error ? ('code' in error.detail ? error.detail.message : error.detail.msg) : error.message;
};

const transUserRole = (role: number) => {
  switch (role) {
    case 0:
      return 'Root Admin';
    case 1:
      return 'Contract Admin';
    case 2:
      return 'Property Admin';
    case 3:
      return 'Staff';
    default:
      throw new Error('Invalid role');
  }
};

const transRsvStatus = (status: AccommRsvStatus) => {
  switch (status) {
    case 'Provisional':
      return '仮押さえ';
    case 'Cancel':
      return 'キャンセル';
    case 'NoShow':
      return 'ノーショウ';
    case 'NotArrived':
      return '未着';
    case 'Staying':
      return 'チェックイン';
    case 'Departed':
      return 'チェックアウト';
    default:
      throw new Error('Invalid reservation status');
  }
};

const transRsvColor = (status: AccommRsvStatus) => {
  switch (status) {
    case 'Provisional':
      return '#FDF9BD';
    case 'Cancel':
      return '#FF034F';
    case 'NoShow':
      return '#FFCDCD';
    case 'NotArrived':
      return '#A9FAF0';
    case 'Staying':
      return '#1BC5F8';
    case 'Departed':
      return '#FFCB77';
    default:
      throw new Error('Invalid reservation status');
  }
};

const transSyncStatus = (status: SyncStatus) => {
  switch (status) {
    case 'NotSet':
      return '未設定';
    case 'Normal':
      return '正常';
    case 'Error':
      return 'エラー';
    default:
      return '';
  }
};

const transRsvType = (rsvType: AccommRsvType) => {
  switch (rsvType) {
    case 'Family':
      return 'ファミリー';
    case 'Group':
      return 'グループ';
    case 'Individual':
      return '個人';
  }
};

type ItemCategory = 'AdditionalFood' | 'Others';

const categoryType = (type: ItemCategory) => {
  switch (type) {
    case 'AdditionalFood':
      return '追加飲食';
    case 'Others':
      return 'その他';
  }
};

const daysBetweenDate = (start: Date, end: Date) => {
  return Math.round((end.valueOf() - start.valueOf()) / (24 * 60 * 60 * 1000));
};

const getDateRange = (start: string | Date, end: string | Date) => {
  const date = [];
  const me = moment(end);

  for (const m = moment(start); m.isBefore(me); m.add(1, 'days')) {
    date.push(m.format('YYYY-MM-DD'));
  }

  return date;
};

const getDateRangeV2 = (start: string | Date, end: string | Date) => {
  const date = [];
  const me = moment(end);

  for (const m = moment(start); m.isBefore(me); m.add(1, 'days')) {
    date.push(m.format('YYYY-MM-DD'));
  }

  if (start === end) {
    date.push(me.format('YYYY-MM-DD'));
  }

  return date;
};

const getDatesOfWeekDayInAMonth = (dayId: number, startDateOfMonth: string) => {
  // need to add 1 day to end date because getDateRange use gt not ge
  const datesOfMonth = getDateRange(
    startDateOfMonth,
    moment(startDateOfMonth).endOf('month').add(1, 'days').format('YYYY-MM-DD')
  );
  const result: string[] = [];
  datesOfMonth.forEach((dateString) => {
    if (moment(dateString).toDate().getDay() === dayId) {
      result.push(dateString);
    }
  });
  return result;
};

const totalFee = (data: AccommRsvRoom[]) => {
  const result = _.sum(
    data.map(
      (item) =>
        _.sum(item.itemRecords.map((x) => x.soldPrice * x.amount)) +
        _.sum(item.planRecords.map((x) => x.soldPrice * x.amount)) +
        _.sum(item.taxRecords.filter((x) => x.taxType !== 'ConsumptionTax').map((x) => x.value))
    )
  );
  return result;
};

const getOriginalPrice = (rsv: AccommReservation, matchedPlan: AccommPlan, plan: PlanRecord | ItemRecord) => {
  const amount = plan.amount;
  const roomTypePricing = matchedPlan.pricingItems?.find((x) => x.roomTypeId === rsv.roomTypeId);
  const keyStrList = [
    'adult1',
    'adult2',
    'adult3',
    'adult4',
    'adult5',
    'adult6',
    'adult7',
    'adult8',
    'adult9',
    'adult10',
  ] as const;
  const keyStr = amount <= 10 ? keyStrList[_.clamp(amount - 1, 0, 9)] : 'adult10';
  const perAdultPricing = (roomTypePricing ? roomTypePricing[keyStr] : 0) ?? 0;
  const paxKey = plan.paxTarget;

  let perChildPricing = 0;
  if (roomTypePricing && paxKey !== 'adult') {
    const childPriceDiscount = roomTypePricing[paxKey];
    let childUnit: 'childUnit1' | 'childUnit2' | 'childUnit3' | 'childUnit4' | 'childUnit5' | 'childUnit6' =
      'childUnit1';
    switch (paxKey) {
      case 'child1':
        childUnit = 'childUnit1';
        break;
      case 'child2':
        childUnit = 'childUnit2';
        break;
      case 'child3':
        childUnit = 'childUnit3';
        break;
      case 'child4':
        childUnit = 'childUnit4';
        break;
      case 'child5':
        childUnit = 'childUnit5';
        break;
      case 'child6':
        childUnit = 'childUnit6';
        break;
    }

    if (roomTypePricing[childUnit] === 'jpy' && childPriceDiscount) {
      perChildPricing = childPriceDiscount;
    } else if (roomTypePricing[childUnit] === 'percent' && childPriceDiscount) {
      perChildPricing = Math.floor((perAdultPricing * childPriceDiscount) / 100);
    }
  }

  return paxKey === 'adult' ? perAdultPricing : perChildPricing;
};

const getRoomName = (id: number | null, roomTypeId: number, property: Property) => {
  const selectedRoom: Room | undefined = property.roomTypes
    .find((x) => x.id === roomTypeId)
    ?.rooms.find((room) => room.id === id);

  return selectedRoom?.name || '';
};

const taxCalculationByDate = (matchedPlan: AccommPlan, soldPrice: number, date: string) => {
  const calendarItem = matchedPlan.calendarItems && matchedPlan.calendarItems.find((x) => x.date === date);
  if (calendarItem) {
    const rank = matchedPlan.ranks.find(({ name }) => name === calendarItem.rank);
    if (rank && rank.unit === 'percent') {
      return Math.floor((rank.amount * soldPrice) / 100);
    } else if (rank && rank.unit === 'jpy') {
      return rank.amount;
    }
  }
  return 0;
};

const hideHeader = `<style>.navbar-custom, .topnav { display: none !important; } .card { box-shadow: none; } html{ background: white; } </style>`;

const getNumberOfDaysSpent = (rsv: AccommReservation | RsvTableColumn) => {
  const toDay = moment();
  const checkInDate = moment(rsv.checkInDate);
  const checkOutDate = moment(rsv.checkOutDate);
  const nights = toDay.diff(checkInDate, 'days');

  if (toDay >= checkOutDate) {
    return rsv.nights + '/' + rsv.nights;
  } else if (toDay.format('YYYY-MM-DD') === checkInDate.format('YYYY-MM-DD')) {
    return '1/' + rsv.nights;
  } else {
    return (nights >= 0 ? nights : 0) + '/' + rsv.nights;
  }
};

const getDaysBetweenDates = (startDate: string, endDate: string) => {
  const dateArr = [];
  while (startDate <= endDate) {
    dateArr.push(startDate);
    const newDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
    startDate = newDate;
  }
  return dateArr;
};

export {
  daysBetweenDate,
  errorClsToMsg,
  errorToMsg,
  getDateRange,
  getDateRangeV2,
  transRsvColor,
  transRsvStatus,
  transRsvType,
  transSyncStatus,
  transUserRole,
  categoryType,
  getDatesOfWeekDayInAMonth,
  getOriginalPrice,
  getRoomName,
  taxCalculationByDate,
  hideHeader,
  getNumberOfDaysSpent,
  getDaysBetweenDates,
  totalFee,
};
